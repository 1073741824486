import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SelfService from "./screens/SelfService";
import ReferenceNumber from "./screens/ReferenceNumber";
import theme from "./theme";

const App = () => {

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route strict path="/:reference_number">
                        <ReferenceNumber />
                    </Route>
                    <Route strict path="/">
                        <SelfService />
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    )
}

export default App;