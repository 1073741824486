import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            light: '#5c9fff',
            main: '#0071cb',
            dark: '#004799'
        },
    },
    shape: {
        borderRadius: "24px"
    },
    typography: {
        button: {
            textTransform: "none"
        }
    }
});

export default theme;