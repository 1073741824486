const vaccines = [
    {id: "Acrodermatitis continua", text: "Acrodermatitis continua"},
    {id: "Bacilles Calmette-Guerin", text: "Bacilles Calmette-Guerin"},
    {id: "Cholera", text: "Cholera"},
    {id: "Diphtheria vaccine", text: "Diphtheria vaccine"},
    {id: "Tetanus and diphtheria toxoid children's dose", text: "Tetanus and diphtheria toxoid children's dose"},
    {id: "Diphtheria and tetanus and Pertussis and Hepatitis B and Polio", text: "Diphtheria and tetanus and Pertussis and Hepatitis B and Polio"},
    {id: "Diphtheria and Tetanus toxoid with acellular pertussis; HepB and IPV vaccine", text: "Diphtheria and Tetanus toxoid with acellular pertussis; HepB and IPV vaccine"},
    {id: "Diphtheria and tetanus toxoid with acellular pertussis and Hib vaccine", text: "Diphtheria and tetanus toxoid with acellular pertussis and Hib vaccine"},
    {id: "Diphtheria and tetanus toxoid with acellular pertussis; Hib and HepB vaccine", text: "Diphtheria and tetanus toxoid with acellular pertussis; Hib and HepB vaccine"},
    {id: "Hexavalent diphtheria", text: "Hexavalent diphtheria"},
    {id: "Diphtheria and tetanus toxoid with acellular pertussusl and IPV vaccine", text: "Diphtheria and tetanus toxoid with acellular pertussusl and IPV vaccine"},
    {id: "Diphtheria and tetanus toxoid vaccine and IPV", text: "Diphtheria and tetanus toxoid vaccine and IPV"},
    {id: "Diptheria and tetanus toxoid with pertussis vaccine", text: "Diptheria and tetanus toxoid with pertussis vaccine"},
    {id: "Pentavalent dephtheria and tetanus toxoid with pertussis; Hib and IPV vaccine", text: "Pentavalent dephtheria and tetanus toxoid with pertussis; Hib and IPV vaccine"},
    {id: "Diphtheria and tetanus toxoid with whole cell pertussis vaccine", text: "Diphtheria and tetanus toxoid with whole cell pertussis vaccine"},
    {id: "Diphtheria and tetanus toxoid with whole cell pertussis and HepB vaccine", text: "Diphtheria and tetanus toxoid with whole cell pertussis and HepB vaccine"},
    {id: "Diphtheria and tetanus toxoid with whole cell pertussis and Hib  vaccine", text: "Diphtheria and tetanus toxoid with whole cell pertussis and Hib  vaccine"},
    {id: "Diphtheria and tetanus toxoid with whole cell pertussis; Hib and HepB vaccine", text: "Diphtheria and tetanus toxoid with whole cell pertussis; Hib and HepB vaccine"},
    {id: "Diphtheria and tetanus toxoid with whole cell pertussis; and IPV vaccine", text: "Diphtheria and tetanus toxoid with whole cell pertussis; and IPV vaccine"},
    {id: "Hepatitis A vaccine", text: "Hepatitis A vaccine"},
    {id: "Hepatitis A; Hepatitis B vaccine", text: "Hepatitis A; Hepatitis B vaccine"},
    {id: "Hepatitis B vaccine", text: "Hepatitis B vaccine"},
    {id: "Hemorrhagic fever with renal syndrome", text: "Hemorrhagic fever with renal syndrome"},
    {id: "Haemophilus influenzae type b vaccine", text: "Haemophilus influenzae type b vaccine"},
    {id: "Haemophilius influenza tybe b; Meningococcal C vaccine", text: "Haemophilius influenza tybe b; Meningococcal C vaccine"},
    {id: "Human Papillomavirus vaccine", text: "Human Papillomavirus vaccine"},
    {id: "Influenza", text: "Influenza"},
    {id: "Inactivated polio vaccine", text: "Inactivated polio vaccine"},
    {id: "Japanese encephalitis", text: "Japanese encephalitis"},
    {id: "Measles vaccine", text: "Measles vaccine"},
    {id: "Meningococcal A", text: "Meningococcal A"},
    {id: "Meningococcal AC", text: "Meningococcal AC"},
    {id: "Meningococcal ACW", text: "Meningococcal ACW"},
    {id: "Meningococcal ACWY", text: "Meningococcal ACWY"},
    {id: "Meningococcal group B; group C vaccine", text: "Meningococcal group B; group C vaccine"},
    {id: "Meningococcal C conjugate vaccine", text: "Meningococcal C conjugate vaccine"},
    {id: "Measles and mumps vaccine", text: "Measles and mumps vaccine"},
    {id: "Measles mumps and rubella vaccine", text: "Measles mumps and rubella vaccine"},
    {id: "Measles; mumps and rubella vaccine", text: "Measles; mumps and rubella vaccine"},
    {id: "Measles; mumps; rubella and varicella vaccine", text: "Measles; mumps; rubella and varicella vaccine"},
    {id: "Measles and rubella vaccine", text: "Measles and rubella vaccine"},
    {id: "Mumps vaccine", text: "Mumps vaccine"},
    {id: "Oral polio vaccine", text: "Oral polio vaccine"},
    {id: "Pneumoccal conjugate vaccine", text: "Pneumoccal conjugate vaccine"},
    {id: "Pneumococcal polysaccharide vaccine", text: "Pneumococcal polysaccharide vaccine"},
    {id: "Rabies vaccine", text: "Rabies vaccine"},
    {id: "Rotavirus vaccine", text: "Rotavirus vaccine"},
    {id: "Rubella vaccine", text: "Rubella vaccine"},
    {id: "Tick borne encephalitis", text: "Tick borne encephalitis"},
    {id: "Tetanus and diphtheria toxoid for older children/adults", text: "Tetanus and diphtheria toxoid for older children/adults"},
    {id: "Tetanus and diphtheria toxoids for older children/adults with inactivated Polio vaccine", text: "Tetanus and diphtheria toxoids for older children/adults with inactivated Polio vaccine"},
    {id: "Tetanus toxoid", text: "Tetanus toxoid"},
    {id: "Typhoid fever vaccine", text: "Typhoid fever vaccine"},
    {id: "Typhoid fever and Hepatitis A vaccine", text: "Typhoid fever and Hepatitis A vaccine"},
    {id: "Varicella vaccine", text: "Varicella vaccine"},
    {id: "Yellow fever vaccine", text: "Yellow fever vaccine"},
    {id: "Diphtheria and Pertussis Polio and Tetanus", text: "Diphtheria and Pertussis Polio and Tetanus"},
    {id: "Hepatitis A", text: "Hepatitis A"},
    {id: "TB", text: "TB"},
    {id: "Papillomavirus", text: "Papillomavirus"},
    {id: "Hepatitis B", text: "Hepatitis B"},
    {id: "Diphtheria and H.influenza and Hepatitis B and Pertussis Polio and Tetanus", text: "Diphtheria and H.influenza and Hepatitis B and Pertussis Polio and Tetanus"},
    {id: "H.influenza", text: "H.influenza"},
    {id: "Strep pneumoniae", text: "Strep pneumoniae"},
    {id: "Measles", text: "Measles"},
    {id: "Meningoccus", text: "Meningoccus"},
    {id: "Neisseria meningitisdis", text: "Neisseria meningitisdis"},
    {id: "Polio", text: "Polio"},
    {id: "Pneumococcus", text: "Pneumococcus"},
    {id: "Measles and Mumps and Rubella", text: "Measles and Mumps and Rubella"},
    {id: "Rabies vaccine", text: "Rabies vaccine"},
    {id: "Rotavirus", text: "Rotavirus"},
    {id: "Yellow fever vaccine", text: "Yellow fever vaccine"},
    {id: "Respiratory Syncital Virus", text: "Respiratory Syncital Virus"},
    {id: "H.influenza and Strep pneumoniae", text: "H.influenza and Strep pneumoniae"},
    {id: "Tetanus and Diphtheria", text: "Tetanus and Diphtheria"},
    {id: "Tetanus", text: "Tetanus"},
    {id: "Diphtheria and Pertussis and Polio and Tetanus", text: "Diphtheria and Pertussis and Polio and Tetanus"},
    {id: "Hepatitis A and Hepatitis B", text: "Hepatitis A and Hepatitis B"},
    {id: "Salmonella typhi", text: "Salmonella typhi"},
    {id: "Varicellar zoster", text: "Varicellar zoster"},
    {id: "Hepatitis A and Salmonella typhi", text: "Hepatitis A and Salmonella typhi"}
];

export default vaccines;