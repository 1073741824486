import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TermsAndConditions = ({content, myself=true}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const close = () => setIsOpen(false);
    const open = () => setIsOpen(true);

    const TermsLink = () => <strong><span className={classes.link} onClick={open}>terms of service</span></strong>;
    const forMyselfMessage = () => <>To continue, please accept our <TermsLink/></>
    const forSomeoneElseMessage = () => <>Please confirm that you accept the <TermsLink /> on behalf of the patient and the patient has granted you permission to complete information on their behalf</>
    const renderMessage = () => myself ? forMyselfMessage() : forSomeoneElseMessage();


    return (
        <>
            {renderMessage()}
            <Dialog
                open={isOpen}
                onClose={close}
                scroll="paper"
            >
                <DialogTitle>
                    <IconButton className={classes.closeButton} onClick={close}><CloseIcon/></IconButton>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText className={classes.content}>
                        {content}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: "absolute!important",
        right: theme.spacing(1),
        top: theme.spacing(1)
    },
    link: {
        textDecoration: "underline",
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    content: {
        whiteSpace: "pre-wrap"
    }
}));

export default TermsAndConditions;