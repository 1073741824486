import React from "react";
import {makeStyles} from "@mui/styles";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    DialogActions,
    Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ErrorPopup = ({isOpen, setIsOpen}) => {
    const classes = useStyles();
    const close = () => setIsOpen(false);

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={close}
                scroll="paper"
            >
                <DialogTitle>
                    <IconButton className={classes.closeButton} onClick={close}><CloseIcon/></IconButton>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText className={classes.content}>
                        Sorry we are unable to submit your information. Please try again or contact the help desk:
                        support@netcaredigital.co.za
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} variant={"outlined"}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: "absolute!important",
        right: theme.spacing(1),
        top: theme.spacing(1)
    },
    link: {
        textDecoration: "underline",
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    content: {
        whiteSpace: "pre-wrap"
    }
}));

export default ErrorPopup;