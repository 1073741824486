export const surgicalProcedures = [
    {id: "Abdominoplasty", text: "Abdominoplasty"},
    {id: "Amputation", text: "Amputation"},
    {id: "Angioplasty", text: "Angioplasty"},
    {id: "Aortic valve replacement", text: "Aortic valve replacement"},
    {id: "Appendectomy", text: "Appendectomy"},
    {id: "Arthroscopy", text: "Arthroscopy"},
    {id: "Biopsy", text: "Biopsy"},
    {id: "Bronchoscopy", text: "Bronchoscopy"},
    {id: "Cardiac catheterisation", text: "Cardiac catheterisation"},
    {id: "Cataract surgery", text: "Cataract surgery"},
    {id: "Cholecystectomy(gall bladder removal)", text: "Cholecystectomy (gall bladder removal)"},
    {id: "Colonoscopy", text: "Colonoscopy"},
    {id: "Colposcopy", text: "Colposcopy"},
    {id: "Cystoscopy", text: "Cystoscopy"},
    {id: "Dilatation and curettage", text: "Dilatation and curettage"},
    {id: "Endoscopy", text: "Endoscopy"},
    {id: "Facelift", text: "Facelift"},
    {id: "Gastric bypass surgery", text: "Gastric bypass surgery"},
    {id: "Gastric sleeve surger", text: "Gastric sleeve surger."},
    {id: "Gastroscopy", text: "Gastroscopy."},
    {id: "Hair transplant", text: "Hair transplant"},
    {id: "Haemorrhoidectomy", text: "Haemorrhoidectomy."},
    {id: "Heart transplants", text: "Heart transplants."},
    {id: "Hemicolectomy", text: "Hemicolectomy"},
    {id: "Hip replacement", text: "Hip replacement."},
    {id: "Hysterectomy", text: "Hysterectomy"},
    {id: "Hysteroscopy", text: "Hysteroscopy."},
    {id: "Kidney transplantsS", text: "Kidney transplantsS."},
    {id: "Knee replacement", text: "Knee replacement"},
    {id: "Laminectomy", text: "Laminectomy"},
    {id: "Laparoscopy", text: "Laparoscopy"},
    {id: "Laparotomy", text: "Laparotomy"},
    {id: "Lap band surgery", text: "Lap band surgery"},
    {id: "Laryngectomy", text: "Laryngectomy"},
    {id: "Liposuction", text: "Liposuction"},
    {id: "Lumbar puncture", text: "Lumbar puncture"},
    {id: "Male circumcision", text: "Male circumcision"},
    {id: "Mastectomy", text: "Mastectomy"},
    {id: "Ovariectomy", text: "Ovariectomy"},
    {id: "PEG tube placement", text: "PEG tube placement"},
    {id: "Rhinoplasty", text: "Rhinoplasty"},
    {id: "Salpingectomy", text: "Salpingectomy"},
    {id: "Tonsillectomy", text: "Tonsillectomy"},
    {id: "Tracheostomy", text: "Tracheostomy"},
    {id: "Transurethral resection of the prostate(TURP)", text: "Transurethral resection of the prostate (TURP)"},
    {id: "Tubal ligation", text: "Tubal ligation"},
    {id: "Vasectomy", text: "Vasectomy"}
];

export const surgicalProceduresWithNone = [
    {id: "None", text: "None"},
    ...surgicalProcedures
];