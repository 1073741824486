export const medicalConditions = [
    {id: "Addison's disease", text: "Addison's disease"},
    {id: "ADHD", text: "ADHD"},
    {id: "Adrenal Hyperplasia", text: "Adrenal Hyperplasia"},
    {id: "Anticoagulants or blood thinners", text: "Anticoagulants or blood thinners"},
    {id: "Arthritis", text: "Arthritis"},
    {id: "Artificial heart valve", text: "Artificial heart valve"},
    {id: "Asthma", text: "Asthma"},
    {id: "Bipolar mood disorder *", text: "Bipolar mood disorder *"},
    {id: "Bladder disease", text: "Bladder disease"},
    {id: "Breastfeeding", text: "Breastfeeding"},
    {id: "Bronchiectasis", text: "Bronchiectasis"},
    {id: "Bronchitis", text: "Bronchitis"},
    {id: "Cancer", text: "Cancer"},
    {id: "Cardiac failure", text: "Cardiac failure"},
    {id: "Cardiomyopathy", text: "Cardiomyopathy"},
    {id: "Cerebral Palsy", text: "Cerebral Palsy"},
    {id: "Chronic obstructive pulmonary disorder", text: "Chronic obstructive pulmonary disorder"},
    {id: "Chronic renal disease", text: "Chronic renal disease"},
    {id: "Coronary artery disease", text: "Coronary artery disease"},
    {id: "Crohn's disease", text: "Crohn's disease"},
    {id: "Depression", text: "Depression"},
    {id: "Diabetes", text: "Diabetes"},
    {id: "Diabetes insipidus", text: "Diabetes insipidus"},
    {id: "Diabetes mellitus types 1 & 2", text: "Diabetes mellitus types 1 & 2"},
    {id: "Down's Syndrome", text: "Down's Syndrome"},
    {id: "Duodenal ulcer", text: "Duodenal ulcer"},
    {id: "Dysrhythmias", text: "Dysrhythmias"},
    {id: "Eczema", text: "Eczema"},
    {id: "Epilepsy", text: "Epilepsy"},
    {id: "Gastric ulcer", text: "Gastric ulcer"},
    {id: "Genetic / congenital disorders", text: "Genetic / congenital disorders"},
    {id: "Glaucoma", text: "Glaucoma"},
    {id: "Haemophilia", text: "Haemophilia"},
    {id: "Heart disease", text: "Heart disease"},
    {id: "Hepatitis", text: "Hepatitis"},
    {id: "Hiatus hernia", text: "Hiatus hernia"},
    {id: "High blood pressure", text: "High blood pressure"},
    {id: "Hormone replacement therapy", text: "Hormone replacement therapy"},
    {id: "Hyperlipidaemia", text: "Hyperlipidaemia"},
    {id: "Hyperlipidaemia or high cholesterol", text: "Hyperlipidaemia or high cholesterol"},
    {id: "Hypertension", text: "Hypertension"},
    {id: "Hypothyroidism", text: "Hypothyroidism"},
    {id: "Jaundice", text: "Jaundice"},
    {id: "Kidney disease", text: "Kidney disease"},
    {id: "Liver failure", text: "Liver failure"},
    {id: "Low blood pressure", text: "Low blood pressure"},
    {id: "Malignant Hyperthermia", text: "Malignant Hyperthermia"},
    {id: "Multiple sclerosis", text: "Multiple sclerosis"},
    {id: "Neuromuscular disorders", text: "Neuromuscular disorders"},
    {id: "Oral contraceptives", text: "Oral contraceptives"},
    {id: "Pacemaker", text: "Pacemaker"},
    {id: "Parkinson's disease", text: "Parkinson's disease"},
    {id: "Peptic ulcer", text: "Peptic ulcer"},
    {id: "Porphyria", text: "Porphyria"},
    {id: "Pregnancy", text: "Pregnancy"},
    {id: "Psychiatric disorders", text: "Psychiatric disorders"},
    {id: "Receiving chemotherapy", text: "Receiving chemotherapy"},
    {id: "Renal failure", text: "Renal failure"},
    {id: "Rheumatoid arthritis", text: "Rheumatoid arthritis"},
    {id: "Schizophrenia", text: "Schizophrenia"},
    {id: "Smoker", text: "Smoker"},
    {id: "Steven Johnson Syndrome", text: "Steven Johnson Syndrome"},
    {id: "Stroke", text: "Stroke"},
    {id: "Systemic lupus erythematosus", text: "Systemic lupus erythematosus"},
    {id: "Thrombosis of veins", text: "Thrombosis of veins"},
    {id: "Thryroid condition", text: "Thryroid condition"},
    {id: "Tuberculosis", text: "Tuberculosis"},
    {id: "Ulcerative colitis", text: "Ulcerative colitis"},
    {id: "Varicose veins", text: "Varicose veins"},
    {id: "Inflammation of lacrimal passages", text: "Inflammation of lacrimal passages"},
    {id: "Open wound of eyeball and other eye structures", text: "Open wound of eyeball and other eye structures"},
    {id: "Primary and open angle glaucoma with failed medical management", text: "Primary and open angle glaucoma with failed medical management"},
    {id: "Purulent endophthalmitis", text: "Purulent endophthalmitis"},
    {id: "Retained intraocular foreign body", text: "Retained intraocular foreign body"},
    {id: "Retinal detachment; tear and other retinal disorders", text: "Retinal detachment; tear and other retinal disorders"},
    {id: "Retinal vascular occlusion; central retinal vein occlusion", text: "Retinal vascular occlusion; central retinal vein occlusion"},
    {id: "Sympathetic uveitis and degenerative disorders and conditions of globe; sight threatening thyroid optopathy", text: "Sympathetic uveitis and degenerative disorders and conditions of globe; sight threatening thyroid optopathy"},
    {id: "Acute and chronic mastoiditis", text: "Acute and chronic mastoiditis"},
    {id: "Acute otitis media", text: "Acute otitis media"},
    {id: "Acute upper airway obstruction; including croup; epiglottitis and acute laryngotracheitis", text: "Acute upper airway obstruction; including croup; epiglottitis and acute laryngotracheitis"},
    {id: "Cancer of oral cavity; pharynx; nose; ear; and larynx - treatable", text: "Cancer of oral cavity; pharynx; nose; ear; and larynx - treatable"},
    {id: "Cancrum oris", text: "Cancrum oris"},
    {id: "Choanal atresia", text: "Choanal atresia"},
    {id: "Cholesteatoma", text: "Cholesteatoma"},
    {id: "Chronic upper airway obstruction; resulting in cor pulmonale", text: "Chronic upper airway obstruction; resulting in cor pulmonale"},
    {id: "Cleft palate and / or cleft lip without airway obstruction", text: "Cleft palate and / or cleft lip without airway obstruction"},
    {id: "Deep open wound of neck; including larynx; fracture of larynx or trachea; open", text: "Deep open wound of neck; including larynx; fracture of larynx or trachea; open"},
    {id: "Foreign body in ear and nose", text: "Foreign body in ear and nose"},
    {id: "Foreign body in pharynx; larynx; trachea; bronchus & esophagus", text: "Foreign body in pharynx; larynx; trachea; bronchus & esophagus"},
    {id: "Fracture of face bones; orbit; jaw; injury to optic and other cranial nerves", text: "Fracture of face bones; orbit; jaw; injury to optic and other cranial nerves"},
    {id: "Leukoplakia of oral mucosa; including tongue", text: "Leukoplakia of oral mucosa; including tongue"},
    {id: "Life - threatening diseases of pharynx NOS; including retropharyngeal abscess", text: "Life - threatening diseases of pharynx NOS; including retropharyngeal abscess"},
    {id: "Open wound of ear - drum", text: "Open wound of ear - drum"},
    {id: "Peritonsillar abscess", text: "Peritonsillar abscess"},
    {id: "Sialoadenitis; abscess / fistula of salivary glands", text: "Sialoadenitis; abscess / fistula of salivary glands"},
    {id: "Bacterial; viral; fungal pneumonia", text: "Bacterial; viral; fungal pneumonia"},
    {id: "# Respiratory failure; regardless of cause", text: "# Respiratory failure; regardless of cause"},
    {id: "Acute asthmatic attack; pneumonia due to respiratory syncytial virus in persons under age 3", text: "Acute asthmatic attack; pneumonia due to respiratory syncytial virus in persons under age 3"},
    {id: "Adult respiratory distress syndrome; inhalation and aspiration pneumonias", text: "Adult respiratory distress syndrome; inhalation and aspiration pneumonias"},
    {id: "Atelectasis(collapse of lung)", text: "Atelectasis(collapse of lung)"},
    {id: "Benign neoplasm of respiratory and intrathoracic organs", text: "Benign neoplasm of respiratory and intrathoracic organs"},
    {id: "Cancer of lung; bronchus; pleura; trachea; mediastinum & other respiratory organs - treatable", text: "Cancer of lung; bronchus; pleura; trachea; mediastinum & other respiratory organs - treatable"},
    {id: "Empyema and abscess of lung", text: "Empyema and abscess of lung"},
    {id: "Frank haemoptysis", text: "Frank haemoptysis"},
    {id: "Hypoplasia and dysplasia of lung", text: "Hypoplasia and dysplasia of lung"},
    {id: "Open fracture of ribs and sternum; multiple rib fractures; flail chest", text: "Open fracture of ribs and sternum; multiple rib fractures; flail chest"},
    {id: "Pneumothorax and haemothorax", text: "Pneumothorax and haemothorax"},
    {id: "Myocarditis; cardiomyopathy; transposition of great vessels; hypoplastic left heart syndrome", text: "Myocarditis; cardiomyopathy; transposition of great vessels; hypoplastic left heart syndrome"},
    {id: "Pericarditis", text: "Pericarditis"},
    {id: "Acute and subacute ischemic heart disease; including myocardial infarction and unstable angina", text: "Acute and subacute ischemic heart disease; including myocardial infarction and unstable angina"},
    {id: "Acute pulmonary heart disease and pulmonary emboli", text: "Acute pulmonary heart disease and pulmonary emboli"},
    {id: "Acute rheumatic fever", text: "Acute rheumatic fever"},
    {id: "Aneurysm of major artery of chest; abdomen; neck; - Unruptured or ruptured NOS", text: "Aneurysm of major artery of chest; abdomen; neck; - Unruptured or ruptured NOS"},
    {id: "Arterial embolism / thrombosis: abdominal aorta; thoracic aorta", text: "Arterial embolism / thrombosis: abdominal aorta; thoracic aorta"},
    {id: "Cardiac failure: acute or recent deterioration of chronic cardiac failure", text: "Cardiac failure: acute or recent deterioration of chronic cardiac failure"},
    {id: "Complete; corrected and other transposition of great vessels", text: "Complete; corrected and other transposition of great vessels"},
    {id: "Coronary artery anomaly", text: "Coronary artery anomaly"},
    {id: "Diseases and disorders of aortic valve NOS", text: "Diseases and disorders of aortic valve NOS"},
    {id: "Diseases of endocardium; endocarditis", text: "Diseases of endocardium; endocarditis"},
    {id: "Diseases of mitral valve", text: "Diseases of mitral valve"},
    {id: "Disorders of arteries: visceral", text: "Disorders of arteries: visceral"},
    {id: "Dissecting or ruptured aortic aneurysm", text: "Dissecting or ruptured aortic aneurysm"},
    {id: "Gangrene; severe atherosclerosis of arteries of extremities; diabetes mellitus with peripheral circulatory disease", text: "Gangrene; severe atherosclerosis of arteries of extremities; diabetes mellitus with peripheral circulatory disease"},
    {id: "Giant cell arteritis; Kawasaki disease; hypersensitivity angiitis", text: "Giant cell arteritis; Kawasaki disease; hypersensitivity angiitis"},
    {id: "Hereditary hemorrhagic telangiectasia", text: "Hereditary hemorrhagic telangiectasia"},
    {id: "Injury to major blood vessels - trunk; head and neck; and upper limbs", text: "Injury to major blood vessels - trunk; head and neck; and upper limbs"},
    {id: "Injury to major blood vessels of extremities", text: "Injury to major blood vessels of extremities"},
    {id: "Life - threatening cardiac arrhythmias", text: "Life - threatening cardiac arrhythmias"},
    {id: "Life - threatening complications of elective cardiac and major vascular procedures", text: "Life - threatening complications of elective cardiac and major vascular procedures"},
    {id: "Multiple valvular disease", text: "Multiple valvular disease"},
    {id: "Other correctable congenital cardiac conditions", text: "Other correctable congenital cardiac conditions"},
    {id: "Patent ductus arteriosus; aortic pulmonary fistula - persistent", text: "Patent ductus arteriosus; aortic pulmonary fistula - persistent"},
    {id: "Phlebitis & thrombophlebitis; deep", text: "Phlebitis & thrombophlebitis; deep"},
    {id: "Rheumatic pericarditis; rheumatic myocarditis", text: "Rheumatic pericarditis; rheumatic myocarditis"},
    {id: "Rupture of papillary muscle", text: "Rupture of papillary muscle"},
    {id: "Tetralogy of Fallot(TOF)", text: "Tetralogy of Fallot(TOF)"},
    {id: "Ventricular septal defect - persistent", text: "Ventricular septal defect - persistent"},
    {id: "Anal Fissure; Anal fistula", text: "Anal Fissure; Anal fistula"},
    {id: "Abscess of intestine", text: "Abscess of intestine"},
    {id: "Acquired hypertrophic pyloric stenosis and other disorders of the stomach and duodenum", text: "Acquired hypertrophic pyloric stenosis and other disorders of the stomach and duodenum"},
    {id: "Acute diverticulitis of colon", text: "Acute diverticulitis of colon"},
    {id: "Acute vascular insufficiency of intestine", text: "Acute vascular insufficiency of intestine"},
    {id: "Amoebiasis; typhoid", text: "Amoebiasis; typhoid"},
    {id: "Anal and rectal polyp", text: "Anal and rectal polyp"},
    {id: "Appendicitis", text: "Appendicitis"},
    {id: "Cancer of retroperitoneum; peritoneum; omentum & mesentery - treatable", text: "Cancer of retroperitoneum; peritoneum; omentum & mesentery - treatable"},
    {id: "Cancer of the gastro - intestinal tract; including oesophagus; stomach; bowel; rectum; anus - treatable", text: "Cancer of the gastro - intestinal tract; including oesophagus; stomach; bowel; rectum; anus - treatable"},
    {id: "Oesophageal stricture", text: "Oesophageal stricture"},
    {id: "Oesophageal varices", text: "Oesophageal varices"},
    {id: "Gastric or intestinal ulcers with hemorrhage or perforation", text: "Gastric or intestinal ulcers with hemorrhage or perforation"},
    {id: "Gastroenteritis and colitis with life - threatening haemorrhage or dehydration; regardless of cause", text: "Gastroenteritis and colitis with life - threatening haemorrhage or dehydration; regardless of cause"},
    {id: "Hernia with obstruction and / or gangrene; uncomplicated hernias under age 18", text: "Hernia with obstruction and / or gangrene; uncomplicated hernias under age 18"},
    {id: "Intestinal obstruction without mention of hernia; symptomatic foreign body in stomach; intestines; colon & rectum", text: "Intestinal obstruction without mention of hernia; symptomatic foreign body in stomach; intestines; colon & rectum"},
    {id: "Paralytic ileus", text: "Paralytic ileus"},
    {id: "Peritoneal adhesion", text: "Peritoneal adhesion"},
    {id: "Peritonitis; regardless of cause", text: "Peritonitis; regardless of cause"},
    {id: "Rectal prolapse", text: "Rectal prolapse"},
    {id: "Rupture of intra - abdominal organ", text: "Rupture of intra - abdominal organ"},
    {id: "Thrombosed and complicated haemorrhoids", text: "Thrombosed and complicated haemorrhoids"},
    {id: "Acute necrosis of liver", text: "Acute necrosis of liver"},
    {id: "Acute pancreatitis", text: "Acute pancreatitis"},
    {id: "Budd - Chiari syndrome; and other venous embolism and thrombosis", text: "Budd - Chiari syndrome; and other venous embolism and thrombosis"},
    {id: "Calculus of bile duct with cholecystitis", text: "Calculus of bile duct with cholecystitis"},
    {id: "Cancer of liver; biliary system and pancreas - treatable", text: "Cancer of liver; biliary system and pancreas - treatable"},
    {id: "Cyst and pseudocyst of pancreas", text: "Cyst and pseudocyst of pancreas"},
    {id: "Disorders of bile duct", text: "Disorders of bile duct"},
    {id: "Gallstone with cholecystitis and / or jaundice", text: "Gallstone with cholecystitis and / or jaundice"},
    {id: "Hepatorenal syndrome", text: "Hepatorenal syndrome"},
    {id: "Liver abscess; pancreatic abscess", text: "Liver abscess; pancreatic abscess"},
    {id: "Liver failure; hepatic vascular obstruction; inborn errors of liver metabolism; biliary atresia", text: "Liver failure; hepatic vascular obstruction; inborn errors of liver metabolism; biliary atresia"},
    {id: "Portal vein thrombosis", text: "Portal vein thrombosis"},
    {id: "Abscess of bursa or tendon", text: "Abscess of bursa or tendon"},
    {id: "Acute osteomyelitis", text: "Acute osteomyelitis"},
    {id: "Cancer of bones - treatable", text: "Cancer of bones - treatable"},
    {id: "Chronic osteomyelitis", text: "Chronic osteomyelitis"},
    {id: "Congenital dislocation of hip; coxa vara and valga; congenital clubfoot", text: "Congenital dislocation of hip; coxa vara and valga; congenital clubfoot"},
    {id: "Crush injuries of trunk; upper limbs; lower limbs; including blood vessels", text: "Crush injuries of trunk; upper limbs; lower limbs; including blood vessels"},
    {id: "Dislocations / fractures of vertebral column without spinal cord injury", text: "Dislocations / fractures of vertebral column without spinal cord injury"},
    {id: "Disruptions of the achilles / quadriceps tendons", text: "Disruptions of the achilles / quadriceps tendons"},
    {id: "Fracture of hip", text: "Fracture of hip"},
    {id: "Injury to internal organs", text: "Injury to internal organs"},
    {id: "Open fracture / dislocation of bones and joints", text: "Open fracture / dislocation of bones and joints"},
    {id: "Pyogenic arthritis", text: "Pyogenic arthritis"},
    {id: "Traumatic amputation of limbs; hands; feet; and digits", text: "Traumatic amputation of limbs; hands; feet; and digits"},
    {id: "Acute lymphadenitis", text: "Acute lymphadenitis"},
    {id: "Burns; greater than 10 % of body surface; or more than 5 % involving head; neck; hands; perineum", text: "Burns; greater than 10 % of body surface; or more than 5 % involving head; neck; hands; perineum"},
    {id: "Cancer of breast - treatable", text: "Cancer of breast - treatable"},
    {id: "Cancer of skin; excluding malignant melanoma - treatable", text: "Cancer of skin; excluding malignant melanoma - treatable"},
    {id: "Cancer of soft tissue; including sarcomas and malignancies of the adnexa - treatable", text: "Cancer of soft tissue; including sarcomas and malignancies of the adnexa - treatable"},
    {id: "Cellulitis and abscesses with risk of organ or limb damage or septiceamia if untreated; necrotizing fasciitis", text: "Cellulitis and abscesses with risk of organ or limb damage or septiceamia if untreated; necrotizing fasciitis"},
    {id: "Disseminated bullous skin disease; including pemphigus; pemphigoid; epidermolysis bullosa; epidermolytic hyperkeratosis", text: "Disseminated bullous skin disease; including pemphigus; pemphigoid; epidermolysis bullosa; epidermolytic hyperkeratosis"},
    {id: "Lethal midline granuloma", text: "Lethal midline granuloma"},
    {id: "Malignant melanoma of skin - treatable", text: "Malignant melanoma of skin - treatable"},
    {id: "Pyoderma; body; deep - seated fungal infections", text: "Pyoderma; body; deep - seated fungal infections"},
    {id: "Toxic epidermal necrolysis and staphylococcal scalded skin syndrome; Stevens - Johnson syndrome", text: "Toxic epidermal necrolysis and staphylococcal scalded skin syndrome; Stevens - Johnson syndrome"},
    {id: "Acute thyroiditis", text: "Acute thyroiditis"},
    {id: "Benign and malignant tumours of pituitary gland with/without hypersecretion syndromes", text: "Benign and malignant tumours of pituitary gland with/without hypersecretion syndromes"},
    {id: "Benign neoplasm of islets of Langerhans", text: "Benign neoplasm of islets of Langerhans"},
    {id: "Cancer of endocrine system; excluding thyroid - treatable", text: "Cancer of endocrine system; excluding thyroid - treatable"},
    {id: "Cancer of thyroid - treatable; carcinoid syndrome", text: "Cancer of thyroid - treatable; carcinoid syndrome"},
    {id: "Congenital hypothyroidism", text: "Congenital hypothyroidism"},
    {id: "Disorder of adrenal secretion NOS", text: "Disorder of adrenal secretion NOS"},
    {id: "Disorders of parathyroid gland; benign neoplasm of parathyroid gland", text: "Disorders of parathyroid gland; benign neoplasm of parathyroid gland"},
    {id: "Hypoglycemic coma; hyperglycemia; diabetic ketoacidosis", text: "Hypoglycemic coma; hyperglycemia; diabetic ketoacidosis"},
    {id: "Life - threatening congenital abnormalities of carbohydrate; lipid; protein and amino acid metabolism", text: "Life - threatening congenital abnormalities of carbohydrate; lipid; protein and amino acid metabolism"},
    {id: "Life - threatening disorders of fluid and electrolyte balance; NOS", text: "Life - threatening disorders of fluid and electrolyte balance; NOS"},
    {id: "Abscess of prostate", text: "Abscess of prostate"},
    {id: "Acute and chronic pyelonephritis; renal and perinephric abscess", text: "Acute and chronic pyelonephritis; renal and perinephric abscess"},
    {id: "Acute glomerulonephritis and nephritic syndrome", text: "Acute glomerulonephritis and nephritic syndrome"},
    {id: "Cancer of penis and other male genital organ - treatable", text: "Cancer of penis and other male genital organ - treatable"},
    {id: "Cancer of prostate gland - treatable", text: "Cancer of prostate gland - treatable"},
    {id: "Cancer of testis - treatable", text: "Cancer of testis - treatable"},
    {id: "Cancer of urinary system including kidney and bladder - treatable", text: "Cancer of urinary system including kidney and bladder - treatable"},
    {id: "Congenital anomalies of urinary system - symptomatic and life - threatening", text: "Congenital anomalies of urinary system - symptomatic and life - threatening"},
    {id: "End stage renal disease regardless of cause", text: "End stage renal disease regardless of cause"},
    {id: "Hyperplasia of the prostate; with acute urinary retention or obstructive renal failure", text: "Hyperplasia of the prostate; with acute urinary retention or obstructive renal failure"},
    {id: "Obstruction of the urogenital tract; regardless of cause", text: "Obstruction of the urogenital tract; regardless of cause"},
    {id: "Torsion of testis", text: "Torsion of testis"},
    {id: "Trauma to the urinary system including ruptured bladder", text: "Trauma to the urinary system including ruptured bladder"},
    {id: "Ureteral fistula(intestinal)", text: "Ureteral fistula(intestinal)"},
    {id: "Vesicoureteral reflux", text: "Vesicoureteral reflux"},
    {id: "Abscesses of Bartholin's gland and vulva", text: "Abscesses of Bartholin's gland and vulva"},
    {id: "Acute pelvic inflammatory disease", text: "Acute pelvic inflammatory disease"},
    {id: "Cancer of Cervix - treatable", text: "Cancer of Cervix - treatable"},
    {id: "Cancer of ovary - treatable", text: "Cancer of ovary - treatable"},
    {id: "Cancer of uterus - treatable", text: "Cancer of uterus - treatable"},
    {id: "Cancer of vagina; vulva and other female genital organs NOS - treatable", text: "Cancer of vagina; vulva and other female genital organs NOS - treatable"},
    {id: "Cervical and breast cancer screening", text: "Cervical and breast cancer screening"},
    {id: "Congenital abnormalities of the female genitalia", text: "Congenital abnormalities of the female genitalia"},
    {id: "Dysplasia of cervix and cervical carcinoma -in -situ; cervical condylomata", text: "Dysplasia of cervix and cervical carcinoma -in -situ; cervical condylomata"},
    {id: "Ectopic pregnancy", text: "Ectopic pregnancy"},
    {id: "Fistula involving female genital tract", text: "Fistula involving female genital tract"},
    {id: "Hydatidiform mole; choriocarcinoma", text: "Hydatidiform mole; choriocarcinoma"},
    {id: "Infertility(Explanatory Note 9 of Annexure A of Regulations)", text: "Infertility(Explanatory Note 9 of Annexure A of Regulations)"},
    {id: "Menopausal management; anomalies of ovaries; primary and secondary amenorrhoea; female sex hormones abnormalities NOS; including hirsutism", text: "Menopausal management; anomalies of ovaries; primary and secondary amenorrhoea; female sex hormones abnormalities NOS; including hirsutism"},
    {id: "Non - inflammatory disorders and benign neoplasms of ovary; fallopian tubes and uterus", text: "Non - inflammatory disorders and benign neoplasms of ovary; fallopian tubes and uterus"},
    {id: "Sexual abuse; including rape", text: "Sexual abuse; including rape"},
    {id: "Spontaneous abortion", text: "Spontaneous abortion"},
    {id: "Torsion of ovary", text: "Torsion of ovary"},
    {id: "Uterine prolapse; cystocele", text: "Uterine prolapse; cystocele"},
    {id: "Voluntary termination of pregnancy", text: "Voluntary termination of pregnancy"},
    {id: "# Low birth weight(under 1000g) with respiratory difficulties", text: "# Low birth weight(under 1000g) with respiratory difficulties"},
    {id: "# Low birth weight(under 2500 grams & > 1000g) with respiratory difficulties", text: "# Low birth weight(under 2500 grams & > 1000g) with respiratory difficulties"},
    {id: "Birth trauma for baby", text: "Birth trauma for baby"},
    {id: "Congenital systemic infections affecting the newborn", text: "Congenital systemic infections affecting the newborn"},
    {id: "Haematological disorders of the newborn", text: "Haematological disorders of the newborn"},
    {id: "Necrotizing enterocolitis in newborn", text: "Necrotizing enterocolitis in newborn"},
    {id: "Neonatal and infant GIT abnormalities and disorders; including malrotation and atresia", text: "Neonatal and infant GIT abnormalities and disorders; including malrotation and atresia"},
    {id: "Neonatal endocrine; metabolic and toxin - induced conditions", text: "Neonatal endocrine; metabolic and toxin - induced conditions"},
    {id: "Neurological abnormalities in the newborn", text: "Neurological abnormalities in the newborn"},
    {id: "Respiratory conditions of newborn", text: "Respiratory conditions of newborn"},
    {id: "Syphilis - congenital; secondary and tertiary", text: "Syphilis - congenital; secondary and tertiary"},
    {id: "# Imminent death regardless of diagnosis", text: "# Imminent death regardless of diagnosis"},
    {id: "Acquired haemolytic anaemias", text: "Acquired haemolytic anaemias"},
    {id: "Acute leukemias; lymphomas", text: "Acute leukemias; lymphomas"},
    {id: "Anaphylactic shock", text: "Anaphylactic shock"},
    {id: "Aplastic anemia; agranulocytosis; other life - threatening hereditary immune deficiencies", text: "Aplastic anemia; agranulocytosis; other life - threatening hereditary immune deficiencies"},
    {id: "Botulism", text: "Botulism"},
    {id: "Cholera; rat - bite fever", text: "Cholera; rat - bite fever"},
    {id: "Chronic Granulomatous disease", text: "Chronic Granulomatous disease"},
    {id: "Coagulation defects", text: "Coagulation defects"},
    {id: "Cysticercosis; other systemic cestode infection", text: "Cysticercosis; other systemic cestode infection"},
    {id: "Deep - seated(excluding nail infections); disseminated and systemic fungal infections", text: "Deep - seated(excluding nail infections); disseminated and systemic fungal infections"},
    {id: "Erysipelas", text: "Erysipelas"},
    {id: "Hereditary angioedema; angioneurotic oedema", text: "Hereditary angioedema; angioneurotic oedema"},
    {id: "Hereditary haemolytic anaemias(e.g.sickle cell); dyserythropoietic anemia(congenital)", text: "Hereditary haemolytic anaemias(e.g.sickle cell); dyserythropoietic anemia(congenital)"},
    {id: "Acute generalised paralysis; including polio and Guillain - Barre", text: "Acute generalised paralysis; including polio and Guillain - Barre"},
    {id: "Immune compromise NOS and associated life - threatening infections NOS", text: "Immune compromise NOS and associated life - threatening infections NOS"},
    {id: "Basal ganglia; extra - pyramidal disorders; other dystonias NOS", text: "Basal ganglia; extra - pyramidal disorders; other dystonias NOS"},
    {id: "Leprosy and other systemic mycobacterial infections; Excluding tuberculosis", text: "Leprosy and other systemic mycobacterial infections; Excluding tuberculosis"},
    {id: "Benign and malignant brain tumours; treatable", text: "Benign and malignant brain tumours; treatable"},
    {id: "Leptospirosis; spirochaetal infections NOS", text: "Leptospirosis; spirochaetal infections NOS"},
    {id: "Compound / depressed fractures of skull", text: "Compound / depressed fractures of skull"},
    {id: "Life - threatening anaemia NOS", text: "Life - threatening anaemia NOS"},
    {id: "Difficulty in breathing; eating; swallowing; bowel; or bladder control due to non - progressive neurological(including spinal) condition or injury", text: "Difficulty in breathing; eating; swallowing; bowel; or bladder control due to non - progressive neurological(including spinal) condition or injury"},
    {id: "Life - threatening conditions due to exposure to the elements; including hypo and hyperthermia; lighting strikes", text: "Life - threatening conditions due to exposure to the elements; including hypo and hyperthermia; lighting strikes"},
    {id: "Encephalocele; congenital hydrocephalus", text: "Encephalocele; congenital hydrocephalus"},
    {id: "Life - threatening rickettsial and other arthropod - borne diseases", text: "Life - threatening rickettsial and other arthropod - borne diseases"},
    {id: "Epilepsy(status epilepticus; initial diagnosis; candidate for neurosurgery)", text: "Epilepsy(status epilepticus; initial diagnosis; candidate for neurosurgery)"},
    {id: "Malaria; trypanosomiasis; other life - threatening parasitic disease", text: "Malaria; trypanosomiasis; other life - threatening parasitic disease"},
    {id: "Intraspinal and Intracranial abscess", text: "Intraspinal and Intracranial abscess"},
    {id: "Metastatic infections; septiceamia", text: "Metastatic infections; septiceamia"},
    {id: "Multiple myeloma and chronic leukaemias", text: "Multiple myeloma and chronic leukaemias"},
    {id: "Myasthenia gravis; muscular dystrophy; neuro - myopathies NOS", text: "Myasthenia gravis; muscular dystrophy; neuro - myopathies NOS"},
    {id: "Poisoning by ingestion; injection; and non - medicinal agents", text: "Poisoning by ingestion; injection; and non - medicinal agents"},
    {id: "Peripheral nerve injury with open wound", text: "Peripheral nerve injury with open wound"},
    {id: "Sexually transmitted diseases with systemic involvement not elsewhere specified", text: "Sexually transmitted diseases with systemic involvement not elsewhere specified"},
    {id: "Reversible CNS abnormalities due to other systemic disease", text: "Reversible CNS abnormalities due to other systemic disease"},
    {id: "Tetanus; anthrax; Whipple's disease", text: "Tetanus; anthrax; Whipple's disease"},
    {id: "Severe / moderate head injury: hematoma / oedema with loss of consciousness", text: "Severe / moderate head injury: hematoma / oedema with loss of consciousness"},
    {id: "Spina Bifida", text: "Spina Bifida"},
    {id: "Toxic effect of gasses; fumes; and vapors", text: "Toxic effect of gasses; fumes; and vapors"},
    {id: "Spinal cord compression; ischaemia or degenerative disease NOS", text: "Spinal cord compression; ischaemia or degenerative disease NOS"},
    {id: "Tuberculosis", text: "Tuberculosis"},
    {id: "Tumour of internal organ(excludes skin): unknown whether benign or malignant", text: "Tumour of internal organ(excludes skin): unknown whether benign or malignant"},
    {id: "Subarachnoid and intracranial hemorrhage / hematoma; compression of brain", text: "Subarachnoid and intracranial hemorrhage / hematoma; compression of brain"},
    {id: "Whooping cough; diptheria", text: "Whooping cough; diptheria"},
    {id: "Tetanus", text: "Tetanus"},
    {id: "Transient cerebral ischaemia; life - threatening cerebrovascular conditions NOS", text: "Transient cerebral ischaemia; life - threatening cerebrovascular conditions NOS"},
    {id: "Abuse or dependence on Psychoactive substance; including alcohol", text: "Abuse or dependence on Psychoactive substance; including alcohol"},
    {id: "Vertebral dislocations / fractures; open or closed with injury to spinal cord", text: "Vertebral dislocations / fractures; open or closed with injury to spinal cord"},
    {id: "Acute delusional mood; anxiety; personality; perception disorders and organic mental disorder caused by drugs", text: "Acute delusional mood; anxiety; personality; perception disorders and organic mental disorder caused by drugs"},
    {id: "Viral meningitis; encephalitis; myelitis and encephalomyelitis", text: "Viral meningitis; encephalitis; myelitis and encephalomyelitis"},
    {id: "Acute stress disorder accompanied by recent significant trauma; including physical or sexual abuse", text: "Acute stress disorder accompanied by recent significant trauma; including physical or sexual abuse"},
    {id: "Alcohol withdrawal delirium; alcohol intoxication delirium", text: "Alcohol withdrawal delirium; alcohol intoxication delirium"},
    {id: "Acute orbital cellulitis", text: "Acute orbital cellulitis"},
    {id: "Anorexia Nervosa and Bulimia Nervosa", text: "Anorexia Nervosa and Bulimia Nervosa"},
    {id: "Angle - closure glaucoma", text: "Angle - closure glaucoma"},
    {id: "Attempted suicide; irrespective of cause", text: "Attempted suicide; irrespective of cause"},
    {id: "Brief reactive psychosis", text: "Brief reactive psychosis"},
    {id: "Cancer of the eye and orbit - treatable", text: "Cancer of the eye and orbit - treatable"},
    {id: "Delirium: Amphetamine; Cocaine; or other psychoactive substance", text: "Delirium: Amphetamine; Cocaine; or other psychoactive substance"},
    {id: "Cataract; aphakia", text: "Cataract; aphakia"},
    {id: "Major affective disorders; including unipolar and bipolar depression", text: "Major affective disorders; including unipolar and bipolar depression"},
    {id: "Corneal ulcer; Superficial injury of eye and adnexa", text: "Corneal ulcer; Superficial injury of eye and adnexa"},
    {id: "Schizophrenic and paranoid delusional disorders", text: "Schizophrenic and paranoid delusional disorders"},
    {id: "Glaucoma associated with disorders of the lens", text: "Glaucoma associated with disorders of the lens"},
    {id: "Treatable dementia", text: "Treatable dementia"},
    {id: "Herpes zoster & herpes simplex with ophthalmic complications", text: "Herpes zoster & herpes simplex with ophthalmic complications"},
    {id: "Hyphaema", text: "Hyphaema"},
    {id: "Covid 19", text: "Covid 19"}
];

export const medicalConditionsWithNone = [
    {id: "None", text: "None"},
    ...medicalConditions
]