export const allergies = [
    { id: "Aspirin", text: "Aspirin" },
    { id: "Cephalosporins", text: "Cephalosporins" },
    { id: "Codeine", text: "Codeine" },
    { id: "Diclofenac", text: "Diclofenac" },
    { id: "Ibuprofen", text: "Ibuprofen" },
    { id: "Iodine", text: "Iodine" },
    { id: "Metoclopramide", text: "Metoclopramide" },
    { id: "Morphine", text: "Morphine" },
    { id: "Non-steroidal anti-inflammatory", text: "Non-steroidal anti-inflammatory" },
    { id: "Paracetamol", text: "Paracetamol" },
    { id: "Penicillin", text: "Penicillin" },
    { id: "Pethidine", text: "Pethidine" },
    { id: "Quinolones", text: "Quinolones" },
    { id: "Scoline", text: "Scoline" },
    { id: "Sulphonamides", text: "Sulphonamides" },
    { id: "Pollen", text: "Pollen" },
    { id: "Dust Mites", text: "Dust Mites" },
    { id: "Mold", text: "Mold" },
    { id: "Animal Dander and Cockroaches", text: "Animal Dander and Cockroaches" },
    { id: "Insect sting", text: "Insect sting" },
    { id: "Latex", text: "Latex" },
    { id: "Milk", text: "Milk" },
    { id: "Eggs", text: "Eggs" },
    { id: "Nuts", text: "Nuts" },
    { id: "Shellfish", text: "Shellfish" },
    { id: "Wheat", text: "Wheat" },
    { id: "Soy", text: "Soy" },
    { id: "Fish", text: "Fish" },
    { id: "Rhinitis", text: "Rhinitis" },
    { id: "Eczema", text: "Eczema" },
    { id: "METHOTREXATE SODIUM", text: "METHOTREXATE SODIUM" },
    { id: "VANCOMYCIN HCL", text: "VANCOMYCIN HCL" },
    { id: "CLARITHROMYCIN", text: "CLARITHROMYCIN" },
    { id: "CEFTRIAXONE SODIUM", text: "CEFTRIAXONE SODIUM" },
    { id: "AMOXICILLIN", text: "AMOXICILLIN" },
    { id: "CIPROFLOXACIN HYDROCHLORIDE", text: "CIPROFLOXACIN HYDROCHLORIDE" },
    { id: "ACYCLOVIR", text: "ACYCLOVIR" },
    { id: "IBUPROFEN", text: "IBUPROFEN" },
    { id: "CEFUROXIME SODIUM", text: "CEFUROXIME SODIUM" },
    { id: "MYCOPHENOLATE MOFETIL", text: "MYCOPHENOLATE MOFETIL" },
    { id: "DICLOFENAC FREE ACID", text: "DICLOFENAC FREE ACID" },
    { id: "CEFOTAXIME SODIUM", text: "CEFOTAXIME SODIUM" },
    { id: "PIMECROLIMUS", text: "PIMECROLIMUS" },
    { id: "LAMOTRIGINE", text: "LAMOTRIGINE" },
    { id: "MOXIFLOXACIN HCL", text: "MOXIFLOXACIN HCL" },
    { id: "CELECOXIB", text: "CELECOXIB" },
    { id: "NEVIRAPINE", text: "NEVIRAPINE" },
    { id: "ZIDOVUDINE (AZT)", text: "ZIDOVUDINE (AZT)" },
    { id: "AZITHROMYCIN", text: "AZITHROMYCIN" },
    { id: "LEVOFLOXACIN", text: "LEVOFLOXACIN" },
    { id: "NAPROXEN", text: "NAPROXEN" },
    { id: "INSULIN LISPRO", text: "INSULIN LISPRO" },
    { id: "INSULIN ASPART", text: "INSULIN ASPART" },
    { id: "CEFTAZIDIME", text: "CEFTAZIDIME" },
    { id: "CODEINE PHOSPHATE", text: "CODEINE PHOSPHATE" },
    { id: "ASPIRIN (ACETYLSALICYLIC ACID)", text: "ASPIRIN (ACETYLSALICYLIC ACID)" },
    { id: "CLINDAMYCIN HCL", text: "CLINDAMYCIN HCL" },
    { id: "TOBRAMYCIN", text: "TOBRAMYCIN" },
    { id: "ABACAVIR SULPHATE", text: "ABACAVIR SULPHATE" },
    { id: "SULPHAMETHOXAZOLE", text: "SULPHAMETHOXAZOLE" },
    { id: "INSULIN GLARGINE", text: "INSULIN GLARGINE" },
    { id: "CEFPODOXIME PROXETIL", text: "CEFPODOXIME PROXETIL" },
    { id: "ROXITHROMYCIN", text: "ROXITHROMYCIN" },
    { id: "DICLOFENAC SODIUM", text: "DICLOFENAC SODIUM" },
    { id: "TACROLIMUS", text: "TACROLIMUS" },
    { id: "ERYTHROMYCIN ESTOLATE", text: "ERYTHROMYCIN ESTOLATE" },
    { id: "PIPERACILLIN", text: "PIPERACILLIN" },
    { id: "CEFUROXIME AXETIL", text: "CEFUROXIME AXETIL" },
    { id: "GATIFLOXACIN", text: "GATIFLOXACIN" },
    { id: "PYRAZINAMIDE", text: "PYRAZINAMIDE" },
    { id: "FLUCLOXACILLIN", text: "FLUCLOXACILLIN" },
    { id: "OXALIPLATIN", text: "OXALIPLATIN" },
    { id: "PACLITAXEL ALBUMIN-BOUND", text: "PACLITAXEL ALBUMIN-BOUND" },
    { id: "TIGECYCLINE", text: "TIGECYCLINE" },
    { id: "CEFEPIME HCL", text: "CEFEPIME HCL" },
    { id: "TEMAFLOXACIN", text: "TEMAFLOXACIN" },
    { id: "STREPTOMYCIN", text: "STREPTOMYCIN" },
    { id: "OXYTETRACYCLINE HCL", text: "OXYTETRACYCLINE HCL" },
    { id: "ETHAMBUTOL HCL", text: "ETHAMBUTOL HCL" },
    { id: "DOXYCYCLINE", text: "DOXYCYCLINE" },
    { id: "CEPHALEXIN", text: "CEPHALEXIN" },
    { id: "CETUXIMAB", text: "CETUXIMAB" },
    { id: "ISONIAZID", text: "ISONIAZID" },
    { id: "GENTAMICIN SULPHATE", text: "GENTAMICIN SULPHATE" },
    { id: "CEFIXIME", text: "CEFIXIME" },
    { id: "DOCETAXEL", text: "DOCETAXEL" },
    { id: "CEFPROZIL", text: "CEFPROZIL" },
    { id: "HYDRALAZINE HCL", text: "HYDRALAZINE HCL" },
    { id: "CEFAZOLIN SODIUM", text: "CEFAZOLIN SODIUM" },
    { id: "PHENOXYMETHYL PENICILLIN", text: "PHENOXYMETHYL PENICILLIN" },
    { id: "HYDROXYCHLOROQUINE", text: "HYDROXYCHLOROQUINE" },
    { id: "CLOXACILLIN SODIUM", text: "CLOXACILLIN SODIUM" },
    { id: "KANAMYCIN SULPHATE", text: "KANAMYCIN SULPHATE" },
    { id: "NORFLOXACIN", text: "NORFLOXACIN" },
    { id: "AMIKACIN SULPHATE", text: "AMIKACIN SULPHATE" },
    { id: "OFLOXACIN", text: "OFLOXACIN" },
    { id: "NITROFURANTOIN", text: "NITROFURANTOIN" },
    { id: "DIHYDROCODEINE TARTRATE", text: "DIHYDROCODEINE TARTRATE" },
    { id: "CABAZITAXEL", text: "CABAZITAXEL" },
    { id: "CICLOSPORIN", text: "CICLOSPORIN" },
    { id: "D-PENICILLAMINE", text: "D-PENICILLAMINE" },
    { id: "CARBAMAZEPINE", text: "CARBAMAZEPINE" },
    { id: "SIROLIMUS", text: "SIROLIMUS" },
    { id: "ALLOPURINOL", text: "ALLOPURINOL" },
    { id: "PACLITAXEL PROTEIN-BOUND", text: "PACLITAXEL PROTEIN-BOUND" },
    { id: "NETILMICIN", text: "NETILMICIN" },
    { id: "CHLORAMPHENICOL", text: "CHLORAMPHENICOL" },
    { id: "AMPICILLIN", text: "AMPICILLIN" },
    { id: "NALIDIXIC ACID", text: "NALIDIXIC ACID" },
    { id: "BENZATHINE PENICILLIN", text: "BENZATHINE PENICILLIN" },
    { id: "CEPHRADINE", text: "CEPHRADINE" },
    { id: "CEFADROXIL", text: "CEFADROXIL" },
    { id: "MINOCYCLINE HCL", text: "MINOCYCLINE HCL" },
    { id: "CLINDAMYCIN PHOSPHATE", text: "CLINDAMYCIN PHOSPHATE" },
    { id: "DAPSONE", text: "DAPSONE" },
    { id: "PIPEMIDIC ACID", text: "PIPEMIDIC ACID" },
    { id: "ERYTHROMYCIN", text: "ERYTHROMYCIN" },
    { id: "ERYTHROMYCIN LACTOBIONATE", text: "ERYTHROMYCIN LACTOBIONATE" },
    { id: "LINCOMYCIN HCL", text: "LINCOMYCIN HCL" },
    { id: "LORACARBEF", text: "LORACARBEF" },
    { id: "PROTAMINE SULPH", text: "PROTAMINE SULPH" },
    { id: "DICLOFENAC DIETHYLAMMONIA", text: "DICLOFENAC DIETHYLAMMONIA" },
    { id: "LEFLUNOMIDE", text: "LEFLUNOMIDE" },
    { id: "CEFTAROLINE FOSAMIL", text: "CEFTAROLINE FOSAMIL" },
    { id: "SULFADIAZINE", text: "SULFADIAZINE" },
    { id: "CEFOXITIN SODIUM", text: "CEFOXITIN SODIUM" },
    { id: "CEFACLOR", text: "CEFACLOR" },
    { id: "INSULIN GLARGINE BIOSIMILAR", text: "INSULIN GLARGINE BIOSIMILAR" },
    { id: "TETRACYCLINE", text: "TETRACYCLINE" },
    { id: "BENZYL PENICILLIN", text: "BENZYL PENICILLIN" },
    { id: "BLEOMYCIN SULPHATE", text: "BLEOMYCIN SULPHATE" },
    { id: "DICLOFENAC POTASSIUM", text: "DICLOFENAC POTASSIUM" },
    { id: "CEFTOLOZANE", text: "CEFTOLOZANE" },
    { id: "RITUXIMAB", text: "RITUXIMAB" },
    { id: "INFLIXIMAB", text: "INFLIXIMAB" },
    { id: "LOMEFLOXACIN HCL", text: "LOMEFLOXACIN HCL" },
    { id: "LYMECYCLINE", text: "LYMECYCLINE" },
    { id: "SULPHANILAMIDE", text: "SULPHANILAMIDE" },
    { id: "TELITHROMYCIN", text: "TELITHROMYCIN" },
    { id: "DICLOFENAC HYDROXYETHYLPYRROLIDINE", text: "DICLOFENAC HYDROXYETHYLPYRROLIDINE" },
    { id: "GEMIFLOXACIN MESYLATE", text: "GEMIFLOXACIN MESYLATE" },
    { id: "LAMIVUDINE", text: "LAMIVUDINE" },
    { id: "RIFAMPICIN (RIFAMPIN)", text: "RIFAMPICIN (RIFAMPIN)" },
    { id: "PARACETAMOL/ACETAMINOPHEN", text: "PARACETAMOL/ACETAMINOPHEN" },
    { id: "HYDROCORTISONE ACETATE", text: "HYDROCORTISONE ACETATE" },
    { id: "AMMONIUM CHLORIDE", text: "AMMONIUM CHLORIDE" },
    { id: "GUAIFENESIN (G GUAIACOLATE)", text: "GUAIFENESIN (G GUAIACOLATE)" },
    { id: "BENZOYL PEROXIDE", text: "BENZOYL PEROXIDE" },
    { id: "CLOPIDOGREL HYDROGEN SULPHATE", text: "CLOPIDOGREL HYDROGEN SULPHATE" },
    { id: "BETAMETHASONE DIPROPIONATE", text: "BETAMETHASONE DIPROPIONATE" },
    { id: "PHOLCODINE", text: "PHOLCODINE" },
    { id: "BROMPHENIRAMINE MALEATE", text: "BROMPHENIRAMINE MALEATE" },
    { id: "DEXAMETHASONE", text: "DEXAMETHASONE" },
    { id: "DOLUTEGRAVIR", text: "DOLUTEGRAVIR" },
    { id: "SURGICAL APPLIANCES AND PRODUCTS", text: "SURGICAL APPLIANCES AND PRODUCTS" },
    { id: "DIPYRIDAMOLE", text: "DIPYRIDAMOLE" },
    { id: "PYRIMETHAMINE", text: "PYRIMETHAMINE" },
    { id: "CHLORPHENIRAMINE MALEATE", text: "CHLORPHENIRAMINE MALEATE" },
    { id: "MORPHINE SULPHATE", text: "MORPHINE SULPHATE" },
    { id: "EUPHORBIUM", text: "EUPHORBIUM" },
    { id: "BETAMETHASONE VALERATE", text: "BETAMETHASONE VALERATE" },
    { id: "DIPHENHYDRAMINE HCL", text: "DIPHENHYDRAMINE HCL" },
    { id: "TRIPROLIDINE HCL", text: "TRIPROLIDINE HCL" },
    { id: "SALICYLAMIDE", text: "SALICYLAMIDE" },
    { id: "ACRIFLAVINE", text: "ACRIFLAVINE" },
    { id: "HONEY", text: "HONEY" }
]

export const allergiesWithNone = [
    { id: "None", text: "None" },
    ...allergies
]