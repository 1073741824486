import React, {useState} from "react";
import {useHistory, useParams, useLocation} from "react-router-dom";
import ConversationUI from "@a2d24/conversation-ui";
import prompts, {initialPrompt} from "../workflows/referenceNumber";
import logo from "../assets/logo.png";
import useStyles from "./styles";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SelfService = () => {
    const classes = useStyles();
    const history = useHistory();
    const [patient_first_name] = useState(useQuery().get("name"));

    const {reference_number} = useParams();

    const onComplete = async () => {
        history.push("/");

    }

    const handleResponse = async (response, promptId) => {
        if (response === undefined) {
            return;
        }
        if (promptId === 'phone_number' || promptId === 're_enter_phone_number') {
            sendSms(response)
        }
    }

    const sendSms = (cellphone_number) => {
        axios.post(
            `${API_URL}/SelfService/send_reference_by_sms`,
            {reference_number: reference_number, cellphone_number: cellphone_number}
        ).then((response) => response.data).catch(e => false)
    }

        return (
            <div className={classes.root}>
                <ConversationUI
                    prompts={prompts}
                    initialPrompt={initialPrompt}
                    onComplete={onComplete}
                    logo={logo}
                    onResponse={handleResponse}
                    messageProps={{reference_number, patient_first_name}}
                />
            </div>
        )
    }

    export default SelfService;