const TermsContent = () => {
    return (
        <div>
            <strong>1. GENERAL INFORMATION</strong><br/>
            <strong>1.1 </strong> Medicross Group (Pty) Ltd (“Medicross”), registration number 1992/002328/07 provides administrative services to a number of medical and dental practices (“the
            Practices”) who are incorporated and registered as private companies and are located at Medicross Clinics across South Africa. The administrative services provided by
            Medicross are inclusive of but not limited to the billing of Patients on behalf of the Practices for the medical services  rendered to the Patients by the healthcare
            practitioners at the Practices. <br />
            <strong>1.2 </strong> Any medical treatment or services rendered to a Patient at a Medicross Clinic by a Practice (the “Services”) shall be subject to the terms and conditions contained herein.<br />

            <br />

            <strong>2. PAYMENT TERMS</strong><br />
            <strong>2.1 </strong> The only person that may sign these terms and conditions are the patient him or herself or a person acting in his/her capacity as parent or guardian of a patient who is under the age of 18 years or does not have the legal capacity to enter into agreements. The person signing this document will be referred to as the Signatory and will be responsible for payment of the Services.<br />
            <strong>2.2 </strong> Patients who are not members of a medical scheme (“Private Patients”) are responsible to pay their accounts immediately after receiving the Services. <br />
            <strong>2.3 </strong> Where a Patient is a member of a medical scheme the account remains the responsibility of the Signatory if not paid in full by such medical scheme within the times permitted by the Medical Schemes Act. <br />
            <strong>2.4 </strong> Although Medicross takes care to ensure accurate and timely submission of accounts to the medical schemes, Medicross will not be held liable for any accounts not received by the medical scheme or repudiated by the medical scheme and the account remains the responsibility of the Signatory until settled in full.<br />
            <strong>2.5 </strong> Interest at a rate of 1% above the prime rate shall accrue on the amount due from the first payment date to date of actual payment and compounded monthly in arrears. <br />
            <strong>2.6 </strong> Should the Signatory fail to pay the account and thereby necessitate legal action, the Signatory undertakes to pay legal costs relating to the recovery of  same  including  attorneys'
fees on the attorney own client scale, collection commission and tracing costs. <br />
            <strong>2.7 </strong> The Signatory hereby gives Medicross permission to verify and asses his/her credit profile with any credit bureau and further consents to Medicross disclosing and providing his/her personal information and payment conduct information (including defaults) to any credit bureau or other Practice administered by Medicross.<br />
            <strong>2.8 </strong> Should a Patient be indebted to Medicross or any other Practice administered by Medicross, the Practice reserves its right to refuse the Patient access to the Practice  and/or
Services offered by the Practice. This clause shall not be applicable in instances where the Patient is suffering from an emergency medical condition. <br />
            <strong>2.9 </strong> The Signatory hereby consents to Medicross invoicing the Signatory for the medical Services rendered to the person receiving the Services (“the Patient”) from the Practice. <br />
            <strong>2.10 </strong> The Signatory acknowledges and consents to the cession by the Practice of all of the Practice claims against the Signatory to Medicross for the face value of such claims. <br />
            
            <br />

            <strong>3. MEDICAL RECORDS AND DATA PROCESSING</strong><br/>
            <strong>3.1 </strong> The Patient authorises Medicross and the Practice to disclose his/her / the Patient’s medical records to all medical practitioners who provide medical care to the Patient or to whom the Patient has been referred, as well as to Medicross staff members who administer the Practice.<br/>
            <strong>3.2 </strong> The Signatory further authorises disclosure of his/ her / the Patient’s medical records to the Patient’s medical scheme or it’s duly appointed providers.<br/>
            <strong>3.3 </strong> The Signatory consents to being contacted regarding any clinical trials that may be of relevance to him/ her / the Patient.<br/>
            <strong>3.4 </strong> The Signatory consents to Medicross processing his/ her /the Patient’s personal information for the purposes of the Protection of Personal Information Act, provided that Medicross will only process such information to the extent that is reasonably required.<br/>
            <strong>3.5 Appointment Bookings:</strong><br/>
            <strong>3.5.1 </strong> Medicross provides an online appointment booking service which allows a Patient to book an appointment with a Practice. In order to provide the appointment booking service, Medicross uses software ("Software") that is owned and licenced by RMed Online Proprietary Limited ("RecoMed"). When a Patient submits personal information, the Software confirms and records this in order to process the booking. By using the Software to make a booking, Patients agree that RecoMed can use any personal information provided through the Software:<br/>
            <ul style={{margin: 0}}>
                <li>To facilitate the booking services;</li>
                <li>For historical, statistical or research purposes; and</li>
                <li>In accordance with the Recomed privacy policy, available at www.recomed.co.za.</li>
            </ul>
            <strong>3.5.2</strong> In respect of the Software, Patients agree that they shall not (and will not allow any third party to): <br />
            <ul style={{margin: 0}}>
                <li>Copy, modify, adapt, translate, or reverse engineer any portion of the Software;</li>
                <li>Remove any copyright, trademark or other proprietary rights notices contained in or on the Software;</li>
                <li>Use any robot, spider, site search/retrieval application, or other automated device, process or means to access, retrieve or index any portion of the Software; or</li>
                <li>Access, retrieve or index any portion of the Software for purposes of constructing or populating a searchable database of reviews related to the health care industry or Providers or otherwise.</li>
            </ul>

            <br/>

            <strong>4. MARKETING AND COMMUNICATIONS</strong><br />
            <strong>4.1</strong> The Signatory consents to receiving general communications and direct marketing from Medicross by sms, fax, automatic calling 4.2 machines or electronic mail.<br />
            <strong>4.2</strong> The Signatory may elect to opt-out of receiving communications at any time.<br />

            <br />

            <strong>5. LIABILITY</strong><br />
            <strong>5.1</strong> Medicross, its directors, officers, employees or agents shall not be liable for any loss, destruction or damage to any Patient’s property, or any other property belonging to, or in the possession of the Patient, or in the safe keeping of Medicross, how so ever such loss, damage or destruction may arise and whether caused by the negligence of any degree of Medicross, its directors, officers, employees or agents.<br />
            <strong>5.2</strong> The Patient authorises Medicross and any employee of Medicross to dispose of any tissue or bodily part removed from the Patient in the  Medicross facility and to dispose of such
            tissue or part in any manner as it may seem fit, including the destruction thereof.  In the case of a female Patient, a bodily part includes a foetus.<br />
            <strong>5.3</strong> The Patient understands and acknowledges that it is the duty of the attending medical practitioner to ensure that the appropriate consent is obtained and the relevant information<br />
            regarding the procedure is provided to the Patient.<br/>
            <strong>5.4</strong> The Patient understands and acknowledges that the medical and dental practitioners, including the auxiliary personnel, radiologists, anaesthetists and the like, are independently contracted to the Practices and are not employed by Medicross, and that in the circumstances Medicross is not liable for the conduct of these professionals whatsoever.<br />
            
            <br />
            
            <strong>6. GENERAL</strong><br />
            <strong>6.1</strong> The Signatory chooses his/her home address as listed in the Patient Information Form as his/her address to receive legal documents (domicilium citandi et executandi) for the
            purposes of legal proceedings and undertakes to notify Medicross, in writing, of any changes to his/her domicillium address.<br />
            <strong>6.2</strong> The Signatory warrants that these terms and conditions have been read, understood and accepted by him/her and that he/she is duly authorised  to sign these terms and conditions
            whether in his/her capacity as Patient or for and on behalf of the Patient (in his/her capacity as parent or legal guardian of the Patient).

        </div>
    )
}

export default TermsContent;