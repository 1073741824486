import gp_prompts, {initialPrompt} from './selfService'
import dentistry_prompts from './dentistrySelfService'

export const GP_WORKFLOW = "GP_WORKFLOW"
export const DENTIST_WORKFLOW = "DENTIST_WORKFLOW"

const WORKFLOWS = {
    GP_WORKFLOW: {
        "prompts":gp_prompts,
        "initialPrompt": initialPrompt,
        "type": "medical"
    },
    DENTIST_WORKFLOW:{
        "prompts": dentistry_prompts,
        "initialPrompt": initialPrompt,
        "type": "dentistry"
    }
};

export default WORKFLOWS;