import React from "react";


const RefNumberText = ({ referenceNumber }) => <>
    You're almost done!
    <br/>
    <br/>
    In order for us to link your data to your medical record, we have generated a unique reference number.
    <br/>
    <br/>
    Please keep a note of this reference number and provide it to the reception desk at the clinic.
    <br/>
    <br/>
    Reference number: <strong>{referenceNumber}</strong>
    <br />
    <br />
    For convenience, would you like us to send an SMS with the reference number?
</>

const RefNumberSomeoneElse = ({ referenceNumber, name}) => <>
    You're almost done!
    <br />
    <br />
    In order for us to link {name}'s data to the medical record, we have generated a unique reference number.
    <br />
    <br />
    Please keep a note of this reference number and provide it to the reception desk at the clinic.
    <br />
    <br />
    Reference number: <strong>{referenceNumber}</strong>
    <br />
    <br />
    For convenience, would you like us to send an SMS with the reference number?
</>

const SmsResentText = ({ referenceNumber }) => <>
    We've tried to send you an SMS with the reference number, again.
    <br/>
    <br/>
    Please keep a note of the reference number, just in case.
    <br/>
    <br/>
    Reference number: <strong>{referenceNumber}</strong>
</>

const SmsResentTextSomeoneElse = ({ referenceNumber }) => <>
    We've tried to send an SMS with the reference number, again.
    <br />
    <br />
    Please keep a note of the reference number, just in case.
    <br />
    <br />
    Reference number: <strong>{referenceNumber}</strong>
</>

const initialPrompt = "send_sms";

const prompts = [
    {
        id: "send_sms",
        type: "option",
        config: {
            message: (responses, messageProps) => messageProps.patient_first_name ? <RefNumberSomeoneElse referenceNumber={messageProps.reference_number} name={messageProps.patient_first_name}/> : <RefNumberText referenceNumber={messageProps.reference_number}/>,
            options: [
                { "id": "yes", text: "Yes" },
                { "id": "no", text: "No" }
            ]
        },
        onResponse: (response) => response === "yes" ? "phone_number" : "finish"
    },
    {
        id: "phone_number",
        type: "phonenumber",
        config: {
            message: (responses, messageProps) => {
                const {patient_first_name} = messageProps;
                return patient_first_name ? "Please enter the cellphone number that we should send the SMS to, eg. 07631129191" : "Please enter your cellphone number, eg. 07631129191"
            }
        },
        onResponse: (response) => "received_sms"
    },
    {
        id: "received_sms",
        type: "option",
        config: {
            message: (responses, messageProps) => {
                const { patient_first_name } = messageProps;
                return patient_first_name ? "We've sent an SMS.\n\nHas it been received?" : "We've sent you an SMS.\n\nHave you received it?";
            },
            options: [
                { "id": "yes", text: "Yes" },
                { "id": "no", text: "No" }
            ]
        },
        onResponse: (response) => response === "yes" ? "finish" : "re_enter_phone_number"
    },
    {
        id: "re_enter_phone_number",
        type: "phonenumber",
        config: {
            message: (responses, messageProps) => {
                const { patient_first_name } = messageProps;
                return patient_first_name ? "Please re-enter the cellphone number" : "Please re-enter your cellphone number";
            }
        },
        onResponse: (response) => "sms_resent"
    },
    {
        id: "sms_resent",
        type: "option",
        config: {
            message: (responses, messageProps) => messageProps.patient_first_name ? <SmsResentTextSomeoneElse referenceNumber={messageProps.reference_number}/> : <SmsResentText referenceNumber={messageProps.reference_number}/>,
            options: [
                {id: "got_it", text: "Got it!"}
            ]
        },
        onResponse: (response) => "finish"
    },
    {
        id: "finish",
        type: "option",
        config: {
            message: (responses, messageProps) => {
                const { patient_first_name } = messageProps;
                return patient_first_name ? `Great, we're all done!\n\nThank you for completing ${patient_first_name}'s information.` : "Great, we're all done!\n\nThank you for completing your information.";
            },
            options: [
                { id: "finish", text: "Finish" }
            ]
        },
        onResponse: () => null
    }
]

export {prompts as default, initialPrompt};