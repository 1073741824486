
import { PROMPT_IDS } from "./consts/promptIds";
import { allergies } from "./consts/allergies";
import { medicalConditions } from "./consts/medicalConditions";
import { surgicalProcedures } from "./consts/surgicalProcedures";

import { userValidation, preVisitAssessment, profilePhoto } from "./selfService";

const yesNoPromptsOptions = [
    { id: "yes", text: "Yes" },
    { id: "no", text: "No" },
    { id: "unknown", text: "Unknown" },
]

const dentistryPrompts = [{
    id: PROMPT_IDS.SMOKING,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Do you smoke?";
            }
            return `Does ${patient_first_name} smoke?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.BLOOD_CLOTTING_DISORDER
}, {
    id: PROMPT_IDS.BLOOD_CLOTTING_DISORDER,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Any blood clotting disorder?";
            }

            return `Does ${patient_first_name} have any blood clotting disorder?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.CORTISONE_THERAPY
}, {
    id: PROMPT_IDS.CORTISONE_THERAPY,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Cortisone therapy past or present?";
            }

            return `Has ${patient_first_name} had cortisone therapy past or present?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.PRESENT_MEDICATION_HERBAL_PREPARATIONS
}, {
    id: PROMPT_IDS.PRESENT_MEDICATION_HERBAL_PREPARATIONS,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Present medication/Herbal preparations?";
            }

            return `Does ${patient_first_name} presently use any medication and/or herbal preparations?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: (response) => response === "yes" ? PROMPT_IDS.PRESENT_MEDICATION_HERBAL_PREPARATIONS_NOTE : PROMPT_IDS.ABNORMAL_REACTIONS_DENTAL_INJECTIONS
}, {
    id: PROMPT_IDS.PRESENT_MEDICATION_HERBAL_PREPARATIONS_NOTE,
    type: "text",
    section: "dentistry",
    config: {
        message: "Please list the medication and/or herbal preparations?",
    },
    onResponse: () => PROMPT_IDS.ABNORMAL_REACTIONS_DENTAL_INJECTIONS
}, {
    id: PROMPT_IDS.ABNORMAL_REACTIONS_DENTAL_INJECTIONS,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Have you experienced any abnormal reactions with dental injections?";
            }
            return `Has ${patient_first_name} experienced any abnormal reactions with dental injections?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.GUMS_BLEED
}, {
    id: PROMPT_IDS.GUMS_BLEED,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Do your gums bleed?";
            }

            return `Does ${patient_first_name} gums bleed?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.FREQUENT_MOUTH_ULCERS
}, {
    id: PROMPT_IDS.FREQUENT_MOUTH_ULCERS,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Do you have frequent ulcers in your mouth?";
            }
            
            return `Does ${patient_first_name} have frequent ulcers in their mouth?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.LOOSE_TEETH
}, {
    id: PROMPT_IDS.LOOSE_TEETH,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Have you noticed any loose teeth?";
            }

            return `Has ${patient_first_name} noticed any loose teeth?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.MOUTH_HABITS
}, {
    id: PROMPT_IDS.MOUTH_HABITS,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Are you conscious of any mouth habits, e.g. clenching or grinding?";
            }

            return `Is ${patient_first_name} conscious of any mouth habits, e.g. clenching or grinding?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.JAW_JOINT_PAIN
}, {
    id: PROMPT_IDS.JAW_JOINT_PAIN,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Do you experience pain in the joints of your jaw?";
            }

            return `Does ${patient_first_name} experience pain in the joints of their jaw?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.GUM_TREATMENTS_OPERATIONS
}, {
    id: PROMPT_IDS.GUM_TREATMENTS_OPERATIONS,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Have you had any gum treatments or operations?";
            }

            return `Has ${patient_first_name} had any gum treatments or operations?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.ABNORMAL_BLEEDING_EXTRACTIONS
}, {
    id: PROMPT_IDS.ABNORMAL_BLEEDING_EXTRACTIONS,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Have you experienced any abnormal bleeding after extractions?";
            }

            return `Has ${patient_first_name} experienced any abnormal bleeding after extractions?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.ORTHODONTIC_TREATMENT
}, {
    id: PROMPT_IDS.ORTHODONTIC_TREATMENT,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Have you had orthodontic treatment?";
            }

            return `Has ${patient_first_name} had orthodontic treatment?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.OTHER_HEALTH_PROBLEMS
}, {
    id: PROMPT_IDS.OTHER_HEALTH_PROBLEMS,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Any other health problems?";
            }

            return `Does ${patient_first_name} have any other health problems?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: (response) => response === "yes" ? PROMPT_IDS.OTHER_HEALTH_PROBLEMS_NOTE : PROMPT_IDS.PREGNANT
}, {
    id: PROMPT_IDS.OTHER_HEALTH_PROBLEMS_NOTE,
    type: "text",
    section: "dentistry",
    config: {
        message: "Please list the other health problems",
    },
    onResponse: (response, id, ctrl) => {
        const { responses } = ctrl.state;
        const gender = responses[PROMPT_IDS.GENDER];
        if (gender !== "male") {
            return PROMPT_IDS.PREGNANT;
        } else {
            return null;
        }
    }
}, {
    id: PROMPT_IDS.PREGNANT,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Are you pregnant?";
            }

            return `Is ${patient_first_name} pregnant?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => PROMPT_IDS.BIRTH_CONTROL_PILL
}, {
    id: PROMPT_IDS.BIRTH_CONTROL_PILL,
    type: "option",
    section: "dentistry",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Are you on the pill?";
            }

            return `Is ${patient_first_name} on the pill?`;
        },
        options: yesNoPromptsOptions,
    },
    onResponse: () => null
}
]

const patientHistory = {
    id: PROMPT_IDS.UPDATE_MEDICAL_HISTORY,
    type: "option",
    section: "patient_history",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Please update your medical history now.";
            }

            return `Please update ${patient_first_name}'s medical history information now.`;
        },
        options: [
            { id: "yes", text: "Continue" },
        ],
    },
    onResponse: (response, id, ctrl) => {
        const { responses } = ctrl.state;
        const isEighteen = responses[PROMPT_IDS.IS_EIGHTEEN] === "yes";

        return isEighteen ? PROMPT_IDS.GENDER : PROMPT_IDS.ALLERGIES
    }
}

const patientGender = {
    id: PROMPT_IDS.GENDER,
    type: "option",
    section: "patient_history",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Please select your gender to help us capture the correct information for you";
            }

            return `Please select ${patient_first_name}'s gender to help us capture the correct information`;
        },
        options: [
            { id: "female", text: "Female" },
            { id: "male", text: "Male" },
            { id: "other", text: "Other" },
            { id: "not_say", text: "I'd rather not say" }
        ]
    },
    onResponse: (response) => PROMPT_IDS.ALLERGIES
}

const allergyWorkflow = [ {
        id: PROMPT_IDS.ALLERGIES,
        type: "autocomplete",
        section: "allergies",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Please select your allergies, e.g. Latex";
                }

                return `Please select ${patient_first_name}'s allergies, e.g. Latex`;
            },
            options: allergies,
            placeholder: "Select",
            freeSolo: true,
            optional: true,
            nullOnSkip: true,
            skipLabel: "None",
        },
        onResponse: (response) => (!response) || response?.trim()?.toLowerCase() === 'none' ? PROMPT_IDS.MEDICAL_CONDITIONS : PROMPT_IDS.MORE_ALLERGIES
    },
    {
        id: PROMPT_IDS.MORE_ALLERGIES,
        type: "option",
        section: "allergies",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Do you have another allergy?";
                }

                return `Does ${patient_first_name} have another allergy?`;
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" },
            ],
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.ALLERGIES : PROMPT_IDS.ALLERGIES_NOTE
    },
    {
        id: "allergies_note",
        type: "text",
        section: "allergies",
        config: {
            message: ({for_who}) => {
                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should note about your allergies";
                }
                return "Please let us know if there's anything else that we should note about the allergies";
            },
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.MEDICAL_CONDITIONS
    }
]


export const medicalConditionWorkflow = [
    {
        id: PROMPT_IDS.MEDICAL_CONDITIONS,
        type: "autocomplete",
        section: "medical_conditions",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Please select your medical conditions, e.g. Hypertension\n\nNote: If not applicable, click ‘None’ and proceed";
                }

                return `Please select ${patient_first_name}'s conditions, e.g. Hypertension\n\nNote: If not applicable, click 'None' and proceed`;
            },
            options: medicalConditions,
            placeholder: "Select",
            freeSolo: true,
            optional: true,
            nullOnSkip: true,
            skipLabel: "None",
        },
        onResponse: (response) => (!response) || response?.trim()?.toLowerCase() === 'none' ? PROMPT_IDS.SURGICAL_PROCEDURES : PROMPT_IDS.MEDICAL_CONDITION_DATES
    },
    {
        id: PROMPT_IDS.MEDICAL_CONDITION_DATES,
        type: "date",
        section: "medical_conditions",
        config: {
            message: ({for_who}) => {
                if (for_who === "myself") {
                    return "When were you diagnosed with this condition?\n\nNote: Select the date nearest to which you are able to identify";
                }

                return "When was this condition diagnosed?\n\nNote: Select the date nearest to which the patient is able to identify";
            },
            max: new Date(),
            optional: true,
        },
        onResponse: (response) => PROMPT_IDS.MORE_MEDICAL_CONDITION
    },
    {
        id: PROMPT_IDS.MORE_MEDICAL_CONDITION,
        type: "option",
        section: "medical_conditions",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Do you have another medical condition?";
                }

                return `Does ${patient_first_name} have another medical condition?`;
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No"}
            ],
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.MEDICAL_CONDITIONS : PROMPT_IDS.MEDICAL_CONDITIONS_NOTE
    },
    {
        id: PROMPT_IDS.MEDICAL_CONDITIONS_NOTE,
        type: "text",
        section: "medical_conditions",
        config: {
            message: ({for_who}) => {
                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should note about your medical conditions";
                }

                return "Please let us know if there's anything else that we should note about the medical conditions";
            },
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.SURGICAL_PROCEDURES
    }
];

export const surgicalProceduresWorkflow = [
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURES,
        type: "autocomplete",
        section: "surgical_procedures",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Please select your surgical procedures\n\nNote: If not applicable, click 'None' and proceed";
                }

                return `Please select ${patient_first_name}'s surgical procedures\n\nNote: If not applicable, click 'None' and proceed`;
            },
            options: surgicalProcedures,
            placeholder: "Select",
            freeSolo: true,
            optional: true,
            nullOnSkip: true,
            skipLabel: "None",
        },
        onResponse: (response) => (!response)|| response?.trim()?.toLowerCase() === 'none' ? PROMPT_IDS.CHRONIC_MEDICATIONS : PROMPT_IDS.SURGICAL_PROCEDURE_DATES
    },
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURE_DATES,
        type: "date",
        section: "surgical_procedures",
        config: {
            message: ({for_who}) => {
                if (for_who === "myself") {
                    return "When did you have this procedure done?\n\nNote: Select the date nearest to which you are able to identify";
                }

                return "When was this procedure done?\n\nSelect the date nearest to which the patient is able to identify";
            },
            max: new Date(),
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.MORE_SURGICAL_PROCEDURE
    },
    {
        id: PROMPT_IDS.MORE_SURGICAL_PROCEDURE,
        type: "option",
        section: "surgical_procedures",
        config: {
            message: ({for_who}) => {
                if (for_who === "myself") {
                    return "Have you had another surgical procedure done?";
                }

                return "Were any other surgical procedures done?";
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" },
            ],
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.SURGICAL_PROCEDURES : PROMPT_IDS.SURGICAL_PROCEDURE_NOTES
    },
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURE_NOTES,
        type: "text",
        section: "surgical_procedures",
        config: {
            message: ({for_who}) => {
                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should know about your surgical procedures";
                }

                return "Please let us know if there's anything else that we should know about the surgical procedures";
            },
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.CHRONIC_MEDICATIONS
    }
];

const chronicMedicationWorkflow = {
    id: PROMPT_IDS.CHRONIC_MEDICATIONS,
    type: "text",
    section: "chronic_medication",
    config: {
        message: ({for_who, patient_first_name}) => {
            if (for_who === "myself") {
                return "Please enter all your chronic medications\n\nNote: If not applicable, click 'None' and proceed";
            }

            return `Please enter all ${patient_first_name}'s chronic medications\n\nNote: If not applicable, click 'None' and proceed`;
        },
        placeholder: "Select",
        optional: true,
        nullOnSkip: true,
        skipLabel: "None",
    },
    onResponse: (response) => PROMPT_IDS.SMOKING
}

const prompts = [
    ...userValidation,
    ...preVisitAssessment,
    ...profilePhoto,
    patientHistory,
    patientGender,
    ...allergyWorkflow,
    ...medicalConditionWorkflow,
    ...surgicalProceduresWorkflow,
    chronicMedicationWorkflow,
    ...dentistryPrompts,
];

export default prompts;